import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo/seo';
import InterviewGrid from '../components/interview-grid/interview-grid';

export default ({ data, location }) => {
  const interviews = data.allInterviewsJson.nodes;

  return (
    <Layout>
      <SEO
        title="Featuring upcoming companies in the crypto world."
        keywords={[`interviews`, `marketers`, `startups`]}
        imageURL={data.file.publicURL}
      />
      <section className="hero">
        <div className="hero-body">
          <div className="container has-text-centered">
            <h2
              className="is-size-4"
              style={{
                marginBottom: '1rem',
                color: '#4a4a4a',
                fontWeight: 400,
              }}
            >
              Please fill this form below with as many answers possible <br />
              and we'll contact you soon!
            </h2>
          </div>
        </div>
      </section>
      <div
        style={{
          margin: '1rem',
          display: 'flex',
          justifyContent: 'center',
          height: 3347,
        }}
      >
        <iframe
          src="https://docs.google.com/forms/d/e/1FAIpQLSeLVMKIHF5AU4WVPkst2w0GLRPVXVSbRqiTJ5w0oDcf-RYkVg/viewform?embedded=true"
          width="700"
          height="3347"
          frameborder="0"
          marginheight="0"
          marginwidth="0"
        >
          Loading...
        </iframe>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    file(relativePath: { eq: "images/logo-og.png" }) {
      publicURL
    }
    allInterviewsJson(sort: { fields: timestamp, order: DESC }) {
      nodes {
        slug
        name
        title
        avatar {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
